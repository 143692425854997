<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
          >Stack Energy
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <div />
    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'DashboardCoreDrawer',

  data: () => ({
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/'
      },
      {
        icon: 'mdi-file',
        title: 'Application',
        to: '/applications'
      },
      {
        group: '/registry',
        icon: 'mdi-image',
        title: 'Registry',
        children: [
          {
            icon: 'mdi-transmisstion-tower',
            title: 'ICPs',
            to: 'icps'
          }
        ]
      }
    ]
  }),

  computed: {
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      }
    },

    computedItems () {
      return this.items.map(this.mapItem)
    }
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title
      }
    }
  }
}
</script>
